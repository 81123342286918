import { createApp } from 'vue'
import App from './App.vue'
import store from "@/store";
import "./common/common.scss"
import Router from "@/router";
import ECharts from "vue-echarts";
import 'echarts'
import common from "@/common/common";

//element plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App);
app.config.globalProperties.$common = common;

app.use(store)
app.use(Router)
app.use(ElementPlus)
app.component('ECharts',ECharts)

app.mount('#app')
