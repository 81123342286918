<template>
  <Header1 selectFlag="home"></Header1>
  <div class="top-msg-back-views flex flex-direction align-center">
    <div class="msg-view flex flex-direction align-center">
      <div>
        <span class="msg-1">WEB3.0 </span>
        <span class="msg-2"></span>
      </div>
      <div>
        <span>GLOBAL </span>
        <span> REAL-PERSON</span>
      </div>
      <div>
        VIDEO SOCIAL PLATFORM
      </div>
    </div>
    <!--    按钮-->
    <div class="button-views flex justify-between">
      <div class="item left">
        <img src="../assets/home/ios-play.png" alt=""/>
        <div class="flex flex-direction">
          <span class="title1">loding...</span>
          <span class="title2">APP store</span>
        </div>
      </div>
      <div class="item">
        <img style="filter: invert(100%) brightness(0%);" src="../assets/home/google-play.png" alt=""/>
        <div class="flex flex-direction">
          <span class="title1">loding...</span>
          <span class="title2">Google Play</span>
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="views-2-root">
    <div class="back-left-img">

    </div>
    <div class="views-2 def-max-width flex justify-between align-center">
      <div class="left-views flex flex-direction align-stretch">
        <div class="txt-1">
          A Global Real-Person Video Social Platform Based on <span style="color:#FF00AE; ">WEB3.0</span> and <span
            style="color: #111111;text-shadow:
    1px 1px 0 #ffffff,
    -1px 1px 0 #ffffff,
    1px -1px 0 #ffffff,
    -1px -1px 0 #ffffff;">AIGC</span> Architecture.
        </div>
        <div class="txt-2">
          Embark on a New Digital Era, Connect with Your True SelfExplore the Infinite Possibilities of Web3 Video
          Dating
        </div>
      </div>
      <div class="right-views">
        <img src="../assets/home/view-2-right.png" alt=""/>
      </div>
    </div>
  </div>

  <!--  vision views-->
  <div class="def-max-width vision-phone-views flex justify-center align-center">
    <img class="back-img-1" src="../assets/home/vison-phone.png" alt=""/>

    <img class="left-icon" src="../assets/home/vison-left-icon.png" alt=""/>
    <img class="right-icon" src="../assets/home/vison-right-icon.png" alt=""/>

    <span class="title-1 title-1-1">Hani VISION</span>
    <span class="title-1 title-1-2">Hani PHONE</span>

    <span class="title-2">
      HANI will produce terminal phones based on AI social ecology machine
    </span>

    <div class="msg-1 flex">
      <span class="msg-1-1">
        Used to expand and undertake various partner applications, and to connect users' intersections Requirement.
      </span>
      <div class="msg-1-2 flex flex-direction">
        <span class="msg-1-2-1"><!--( DON'T MISSYOUR CHANCE)--></span>
        <span>VIRTUAL AND PHYSICAL SENSING CLUSTER</span>
      </div>

    </div>

  </div>

  <div class="views-4-root ">
    <div class="def-max-width flex flex-direction align-center">
      <div class="title-view flex justify-center">
        <span class="title">
          <span class="txt-1">WEB3.0</span> VIDEO <br/>DATING PLATFORM
        </span>
        <div class="title-1">
          Embark on a new Digital Era,Connect with your true Self- Explore the Infinite possibilities of Web3 Video
          Dating
        </div>
      </div>
      <img class="body-img" src="../assets/home/views-4-img.png" alt="">
    </div>
  </div>

  <div class="views-5">
    <div class="def-max-width flex flex-direction align-center">
      <div class="title-1">
        APPEARANCE MINING RIG
      </div>
      <div class="title-2">
        HANI POWER
      </div>
      <div class="title-line"></div>
      <div class="title-3">
        Platform hosts can generate Hani Power Appearance Mining Rigs. Users, by investing inthese Appearance Mining
        Rigs, generate computing power corresponding to the host'sonline duration and interactions with users, thereby
        producing $Hani coins. This rewardsinvestors with substantial returns, thus promoting the prosperity of the
        ecosystem.
      </div>
      <img class="box-img" src="../assets/home/views-5-box.png" alt=""/>
    </div>
  </div>
  <div class="views-5-bottom flex justify-around align-center">
    <img class="box-1" src="../assets/home/box/box-1.png" alt=""/>
    <img class="box-2" src="../assets/home/box/box-2.png" alt=""/>
    <img class="box-3" src="../assets/home/box/box-3.png" alt=""/>
    <img class="box-4" src="../assets/home/box/box-4.png" alt=""/>
  </div>

  <div class="views-5-1">
    <div class="def-max-width flex flex-direction align-center">
      <span class="title">
        DEVELOPMENT ROADMAP
      </span>
      <div class="time-cl-views flex flex-direction align-center">
        <img class="img-pc" src="../assets/home/time-img-pc.png" alt=""/>
        <img class="img-h5" src="../assets/home/time-img-h5.png" alt=""/>
      </div>
    </div>
  </div>

  <div class="views-6">
    <div class="def-max-width flex flex-direction align-center">
      <span class="title">
        HANI TOKEN ECONOMICS
      </span>
      <div class="body-views flex">
        <div class="left-views flex flex-direction align-stretch">
          <span class="title-1">Basic <span class="title-1-1">metrics</span></span>
          <div class="item flex justify-between">
            <span>Ticker</span>
            <span>$Hani Coin</span>
          </div>
          <div class="item flex justify-between">
            <span>Total supply</span>
            <span>10,000,000,000</span>
          </div>
<!--          <div class="item flex justify-between">-->
<!--            <span>Circulating supply</span>-->
<!--            <span>~188,767,286</span>-->
<!--          </div>-->
          <div class="item flex justify-between">
            <span>Price</span>
            <span>$</span>
          </div>
        </div>
        <div class="right-views">
          <img src="../assets/home/token-icon.png" alt=""/>
        </div>

      </div>
    </div>
  </div>

  <div class="views-7">
    <div class="def-max-width flex justify-center align-center">
      <e-charts class="tu-biao-views" :option="option"></e-charts>
    </div>
  </div>

<!--  <div class="views-8 def-max-width flex justify-center align-center">-->
<!--&lt;!&ndash;    关注官方推特：<a href="https://x.com/hani_web3?s=21">x.com/hani_web3?s=21</a> &ndash;&gt;-->
<!--&lt;!&ndash;    Telegram频道：<a href="https://t.me/HaniparkAnnouncements" >t.me/HaniparkAnnouncements</a>&ndash;&gt;-->
<!--    <a href="https://x.com/hani_web3?s=21" target="_blank">-->
<!--      <img src="../assets/ido/icon-x.png" alt="">-->
<!--    </a>-->
<!--    <a href="https://x.com/hani_web3?s=21" target="_blank" style="margin-left: 2rem;">-->
<!--      <img src="../assets/ido/icon-t.png" alt="">-->
<!--    </a>-->

<!--  </div>-->

  <div class="views-9 flex flex-direction justify-center align-center">
    <div class="title" >Join us and learn more</div>
    <div class="def-max-width flex justify-center flex-wrap align-stretch">
      <div class="img-item">
        <a href="https://web.hanipark.ai" target="_blank">
          <img class="img1" src="../assets/home/join/img-1.png" alt=""/>
        </a>
      </div>
      <div class="img-item">
        <a href="https://x.com/hani_web3?s=21" target="_blank">
          <img class="img2" src="../assets/home/join/img-2.png" alt="" />
        </a>
      </div>
      <div class="img-item">
        <a href="https://t.me/HaniparkAnnouncements" target="_blank">
          <img class="img3" src="../assets/home/join/img-3.png" alt="" />
        </a>
      </div>
      <div class="img-item">
        <a href="https://hanipark.gitbook.io/untitled/" target="_blank">
          <img class="img4" src="../assets/home/join/img-4.png" alt="" />
        </a>
      </div>
      <div class="img-item">
        <a href="https://t.me/HaniParkchatEN" target="_blank">
          <img class="img5" src="../assets/home/join/img-5.png" alt="" />
        </a>
      </div>
      <div class="img-item">
        <a href="https://t.me/HaniParkchat" target="_blank">
          <img class="img6" src="../assets/home/join/img-6.png" alt="" />
        </a>
      </div>
      <div class="img-item">
        <a href="https://t.me/hanipark_bot" target="_blank">
          <img class="img7" src="../assets/home/join/img-7.png" alt="" />
        </a>
      </div>
    </div>
  </div>



  <BottomView></BottomView>


</template>
<script>
import Header1 from "../components/header.vue";
import {THEME_KEY} from 'vue-echarts';
import BottomView from "@/components/BottomView.vue";

export default {
  name: "home",
  components: {BottomView, Header1},
  provide: {
    // [THEME_KEY]: 'dark'
  },
  data() {
    return {
      chartsData: [
        {name: 'BehaviorMining', value: 55},
        {name: 'Airdrop', value: 5},
        {name: 'Node', value: 5},
        {name: 'IDO', value: 5},
        {name: 'Team', value: 8},
        {name: 'LiquidityProvision', value: 10},
        {name: 'Staking Mining', value: 7},
        {name: 'Community Management', value: 5},
      ],
    }
  },
  computed: {
    option() {
      let option = {
        legend: {
          width: "80%",
          bottom: '0%',
          itemGap: 25,
          textStyle: {
            color: "#fff",
            fontSize:"10"
          }
        },
        series: {
          top: 0 + '%',
          height:"95%",
          type: 'pie',
          radius: ['30%', '55%'],
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1
          },
          label: {
            alignTo: 'edge',
            // formatter: '{name|{b}}\n{time|{c} 小时}',
            formatter: '{name|{b}}\n{time|{c} %}',
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 15,
            fontSize: '12',
            color: '#fff',
            rich: {
              time: {
                fontSize: "12",
                color: '#999'
              }
            }
          },
          labelLine: {
            length: 15,
            length2: 0,
            maxSurfaceAngle: 80
          },
          data: this.chartsData
        }
        // xAxis: {
        //   type: 'category',
        //   data: ['A', 'B', 'C']
        // },
        // yAxis: {
        //   type: 'value'
        // },
        // series: [{
        //   data: [120, 200, 150],
        //   type: 'line'
        // }]

      }
      return option
    }
  }
}
</script>

<style lang="scss" scoped>
.top-msg-back-views {
  width: 100%;
  height: 60rem;
  padding-top: 20rem;

  background-image: url('../assets/home/home-top-back.png'); /* 设置背景图 */
  background-size: cover; /* 背景图铺满容器 */
  background-position: center center; /* 背景图居中显示 */
  background-repeat: no-repeat; /* 防止背景图重复 */

  .msg-view {
    font-size: 4.94rem;
    font-weight: bold;
    color: #fff;

    .msg-1 {
      font-size: 6.35rem;
      background: linear-gradient(90deg, #ff7e5f, #feb47b); /* 渐变背景 */
      -webkit-background-clip: text; /* 将背景裁剪到文字区域 */
      -webkit-text-fill-color: transparent; /* 使文字颜色透明 */
    }

    .msg-2 {
      color: #00FFFF;
      font-size: 5.91rem;
    }


  }

  .button-views {
    margin-top: 3rem;

    .item {
      width: 18.81rem;
      height: 5.57rem;
      border-radius: .63rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2.65rem;
      background: #fff;

      &:last-child {
        margin-right: 0;
      }

      &.left {
        background: #FD00A9;
        color: #fff;
      }

      img {
        width: 2.16rem;
        margin-right: .86rem;

      }

      .title1 {
        font-size: .97rem;
      }

      .title2 {
        font-size: 1.3rem;
      }

    }
  }

}

.views-2-root {
  position: relative;

  .back-left-img {
    position: absolute;
    background-image: url('../assets/home/left-back-img.png'); /* 设置背景图 */
    background-position: right bottom; /* 将背景图定位到右下角 */
    background-size: auto 100%; /* 背景图高度撑满容器，宽度自动调整 */
    background-repeat: no-repeat; /* 防止背景图重复 */
    width: 25rem;
    height: 47.7rem;
    left: 0;
    top: -2rem;
  }


}

.views-2 {
  margin: 4.65rem auto;


  .left-views {
    flex: 1 0 10rem;
    max-width: 36rem;
    margin-right: 2rem;
    color: #fff;

    .txt-1 {
      font-size: 3.24rem;
      font-weight: 500;
    }

    .txt-2 {
      font-size: 1.3rem;
      margin-top: 3rem;
    }
  }

  .right-views {
    flex: 0 1 45rem;

    img {
      width: 100%;
    }

  }

}

.vision-phone-views {
  position: relative;
  color: #fff;


  .back-img-1 {
    width: 84%;
    padding: 6.5rem 0 12.65rem;


  }

  .left-icon {
    width: 3.08rem;
    position: absolute;
    left: .7rem;
    top: 11rem;

  }

  .right-icon {
    width: 2.97rem;
    position: absolute;
    right: .7rem;
    bottom: 5rem;
  }

  .title-1 {
    position: absolute;
    font-size: 1.62rem;

    &.title-1-1 {
      top: 2.92rem;
      left: 30%;
    }

    &.title-1-2 {
      top: 3.24rem;
      right: 16%;
      transform: translateX(-50%);
    }

  }

  .title-2 {
    width: 22rem;
    font-weight: 400;
    position: absolute;
    top: 0;
    left: 50%;
    font-size: .97rem;


  }

  .msg-1 {
    width: 84%;
    position: absolute;
    bottom: 9rem;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;

    .msg-1-1 {
      font-size: .97rem;
      margin-right: 3.13rem;
      width: 17.89rem;

    }

    .msg-1-2 {
      font-size: 0.97rem;

      .msg-1-2-1 {
        margin-bottom: 2.5rem;
        font-size: 1.62rem;
      }

    }

  }


}


.views-4-root {
  margin-bottom: 4.6rem;
  padding-top: 5.68rem;
  min-height: 60rem;
  background-image: url('../assets/home/views-4-back.png'); /* 设置背景图 */
  background-size: cover; /* 背景图铺满容器 */
  background-position: center center; /* 背景图居中显示 */
  background-repeat: no-repeat; /* 防止背景图重复 */
  color: #fff;

  .title-view {
    position: relative;
    width: 40rem;
    height: 12.7rem;
    background-image: url('../assets/home/views-4-title-back.png'); /* 设置背景图 */
    background-size: cover; /* 背景图铺满容器 */
    background-position: center 100%; /* 背景图居中显示 */
    background-repeat: no-repeat; /* 防止背景图重复 */
    font-size: 3.24rem;
    padding: 1.5rem 2rem;

    .title {
      text-align: center;
      font-weight: 600;
      font-family: Montserrat;
    }

    .txt-1 {
      color: #FF00AE;
    }

    .title-1 {
      text-align: center;
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      color: rgba(255, 255, 255, .7);
      width: 26rem;
      font-size: 0.86rem;
      line-height: 2;
    }


  }

  .body-img {
    width: 99%;
    max-width: 100%;

  }


}


.views-5 {
  min-height: 51rem;
  background-image: url('../assets/home/views-5-back.png'); /* 设置背景图 */
  background-size: cover; /* 背景图铺满容器 */
  background-position: 100% 100%; /* 背景图居中显示 */
  background-repeat: no-repeat; /* 防止背景图重复 */
  color: #fff;
  position: relative;


  .title-1 {
    font-size: 3.24rem;

  }

  .title-2 {
    font-size: 4.43rem;
    color: #FF00AE;
  }

  .title-line {
    width: 2.86rem;
    height: .32rem;
    background: #FF00AE;
    border-radius: .15rem;
    margin: 1.5rem 0;

  }

  .title-3 {
    font-size: 0.86rem;
    text-align: center;
    max-width: 80%;


  }

  .box-img {
    width: 47.57rem;
    margin-bottom: 1rem;


  }


}

.views-5-bottom {
  width: 100%;
  height: 5.57rem;
  background: #FF00AE;

  .box-1 {
    width: 13.73rem;
  }

  .box-2 {
    width: 6.92rem;
  }

  .box-3 {
    width: 10.59rem;
  }

  .box-4 {
    width: 10.65rem;
  }

}


.views-5-1 {
  margin-top: 6rem;
  color: #fff;

  .title {
    font-size: 3.24rem;
    font-weight: bold;
    margin-bottom: 6.35rem;

  }

  .time-cl-views {
    img {
      width: 63rem;
    }

    .img-pc {
      display: block;

    }

    .img-h5 {
      display: none;
    }
  }

}

.views-6 {
  color: #fff;
  margin-top: 6rem;

  .title {
    font-size: 3.24rem;
    font-weight: bold;
    margin-bottom: 6.35rem;

  }

  .body-views {
    align-items: center;
    font-size: 1.3rem;
    font-weight: 400;

    .left-views {

      .title-1 {
        margin-bottom: 2rem;

        .title-1-1 {
          color: #FF00AE;
        }

      }

      .item {
        padding: 2rem 1.3rem;
        border-bottom: 1px solid #5E5E5E;
        width: 28rem;


      }
    }

    .right-views {
      img {
        width: 36.91rem;
        margin-left: 5rem;
      }
    }

  }

}

.views-7 {
  .tu-biao-views {
    width: 90%;
    height: 50rem;
    margin-bottom: 10rem;


  }
}




.views-8{
  color: #fff;
  font-size: 1.8rem;
  margin-bottom: 10rem;
  img{
    width: 4rem;
  }
}

.views-9{
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  background: #000000;

  .title{
    margin-bottom: 1.6rem;color: rgba(255,255,255,.8);font-size: 1rem;
  }

  .img-item{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 1rem;
    padding: .9rem .8rem;
    border: 1px solid rgba(255,255,255,.2);
    margin-bottom: .67rem;
    margin-right: .5rem;

    .img1{
      width: 7.62rem;
    }
    .img2{
      width: 6.78rem;
    }
    .img3{
      width: 7.89rem;
    }
    .img4{
      width: 8.56rem;
    }
    .img5{
      width: 10.33rem;
    }
    .img6{
      width: 12.67rem;
    }
    .img7{
      width: 10.6rem;
    }

  }
}



@media screen and (max-width: 768px) {

  .top-msg-back-views {

    height: 44rem;
    padding-top: 15rem;
    .msg-view {
      font-size: 3.18rem;

      .msg-1 {
        font-size: 3.8rem;
      }

      .msg-2 {
        color: #00FFFF;
        font-size: 3.6rem;
      }


    }

    .button-views {

      .item {
        width: 22rem;
        height: 6.6rem;

        img {
          width: 3rem;

        }
      }
    }
  }

  .views-2 {


    .left-views {
      .txt-1 {
        font-size: 2.18rem;
      }

      .txt-2 {

      }
    }

    .right-views {
      flex: 0 1 35rem;

      img {
      }

    }

  }

  .vision-phone-views {
    margin-top: 10rem;
    .title-1{

      &.title-1-2{
        right: 12%;
      }
    }

    .title-2 {
      font-size: 1.3rem;
      width: 16rem;
      left: 47%;
      top: -2rem;
    }

    .title-2{
      top: -4rem;
    }

    .msg-1 {

      .msg-1-1 {
        font-size: .97rem;

      }

      .msg-1-2 {
        font-size: 0.97rem;

        .msg-1-2-1 {

        }

      }

    }
  }



  .views-4-root {
    padding-top: 2.56rem;

    .title-view {


      .title-1 {
        font-size: 1.3rem;
        line-height: 1.5;
      }
    }
  }


  .views-5 {
    .title-3 {
      font-size: 2.6rem;
      max-width: 90%;


    }


  }

  .views-5-1 {
    margin-top: 6rem;
    color: #fff;

    .title {
      font-size: 3.24rem;

    }

    .time-cl-views {
      img {
        width: 66rem;
        max-width: 90%;
      }

      .img-pc {
        display: none;

      }

      .img-h5 {
        display: block;
      }
    }

  }

  .views-6 {
    .title {

    }

    .body-views {
      align-self: stretch;
      font-size: 2.6rem;


      .left-views {
        width: 100%;

        .title-1 {

        }

        .item {
          width: 95%;

        }
      }

      .right-views {
        display: none;
      }

    }

  }


  .views-7 {
    .tu-biao-views {
      height: 100rem;


    }
  }

  .views-9{

    .title{
      font-size: 2rem;
    }

    .img-item{
      .img1{
        width: 11rem;
      }
      .img2{
        width: 10rem;
      }
      .img3{
        width: 12rem;
      }
      .img4{
        width: 13rem;
      }
      .img5{
        width: 15rem;
      }
      .img6{
        width: 17rem;
      }
      .img7{
        width: 16rem;
      }

    }
  }

}

</style>
