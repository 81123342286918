<template>
  <Header1 select-flag="ido"></Header1>
  <div class="views-1">
    <div class="def-max-width flex">
      <img class="right-back-img" src="../assets/ido/views-1-right-img.png" alt="">

      <div class="left-views flex flex-direction">
        <span class="title">Hani Coin <span class="c1">IDO</span> </span>
        <span class="msg">
          Use the $Hani Coin for tipping and transactions, without worrying about the cumbersome processes of the traditional financial
system. It has a constant deflationary issuance, allowing everything to return to its intrinsic value.
        </span>
        <div class="button-views flex justify-between">
          <div class="item left">
            <img src="../assets/home/ios-play.png" alt=""/>
            <div class="flex flex-direction">
              <span class="title1">loding...</span>
              <span class="title2">APP store</span>
            </div>
          </div>
          <div class="item">
            <img style="filter: invert(100%) brightness(0%);" src="../assets/home/google-play.png" alt=""/>
            <div class="flex flex-direction">
              <span class="title1">loding...</span>
              <span class="title2">Google Play</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="views-2">
    <div class="def-max-width flex flex-direction align-center">
      <span class="title">Time: To be determined</span>
      <div class="time-views flex justify-center align-center">
        <div class="item flex flex-direction">
          <div class="num-view">
<!--            {{timeList.days}}-->
            00
          </div>
          <span class="time-title">DAYS</span>
        </div>
        <span class="mao-hao">:</span>
        <div class="item flex flex-direction">
          <div class="num-view">
<!--            {{timeList.hours}}-->
            00
          </div>
          <span class="time-title">HOURS</span>
        </div>
        <span class="mao-hao">:</span>
        <div class="item flex flex-direction">
          <div class="num-view">
<!--            {{timeList.minutes}}-->
            00
          </div>
          <span class="time-title">MINUTES</span>
        </div>
        <span class="mao-hao">:</span>
        <div class="item flex flex-direction">
          <div class="num-view">
<!--            {{timeList.seconds}}-->
            00
          </div>
          <span class="time-title">SECONDS</span>
        </div>
      </div>

      <div class="msg">
        The time and method of token distribution
        will be announced on the official Twitter account, so please stay tuned for further updates
      </div>

      <div class="x-views flex align-center">
        <img src="../assets/ido/icon-x.png" alt="">
        <span>
          @Hani_web3
        </span>
      </div>

    </div>
  </div>


  <bottom-view></bottom-view>
</template>

<script>
import Header1 from "@/components/header.vue";
import BottomView from "@/components/BottomView.vue";

export default {
  name: "Ido",
  components: {BottomView, Header1},
  data(){
    return {
      lastTime: new Date("2024-08-30 23:59:59"),
      nowTime: new Date(),
      timeIn:null,
    }
  },
  computed:{
    timeList(){
      // 将输入的时间字符串解析为 Date 对象
      const lastTime = this.lastTime;
      // 获取当前时间
      const now = this.nowTime;

      // 计算时间差（以毫秒为单位）
      // 计算时间差（以毫秒为单位）
      let timeDiff = Math.abs(now - lastTime);

      // 计算天、小时、分钟、秒
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      timeDiff -= days * 1000 * 60 * 60 * 24;
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      timeDiff -= hours * 1000 * 60 * 60;
      const minutes = Math.floor(timeDiff / (1000 * 60));
      timeDiff -= minutes * 1000 * 60;
      const seconds = Math.floor(timeDiff / 1000);

      // 格式化为两位数，前面补0
      const pad = (num) => num.toString().padStart(2, '0');

      // 将结果存放在一个对象中
      const timeInterval = {
        days: pad(days),
        hours: pad(hours),
        minutes: pad(minutes),
        seconds: pad(seconds)
      };
      // console.log( this.$common.utilsMethods.timeInterval(now,lastTime))
      return timeInterval;
    }
  },
  mounted() {
    this.timeIn = setInterval(()=>{
      this.nowTime = new Date();
    },1000)
  },
  unmounted() {
    clearInterval(this.timeIn)
  }

}
</script>


<style lang="scss" scoped>


.views-1 {
  padding: 7.57rem 0;
  position: relative;
  color: #fff;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/ido/views-1-back.png');
    background-size: 100% 100%;
    background-repeat: no-repeat; /* 防止背景图重复 */
    opacity: 0.2; /* 设置背景图片的透明度 */
    z-index: -1; /* 让伪元素在内容下面 */
    pointer-events: none; /* 防止伪元素影响点击操作 */
  }

  .right-back-img {
    position: absolute;
    width: 49rem;
    right: 0;    z-index: -1;

  }

  .left-views {
    padding-left: 1rem;
    .title {
      font-size: 3.89rem;
      font-weight: bold;
      margin-bottom: 2rem;

      .c1 {
        color: #FF00AE;
      }

    }

    .msg {
      font-size: 1.3rem;
      line-height: 1.62rem;
      opacity: 0.8;
      width: 43.46rem;
      margin-bottom: 4rem;
    }

    .button-views {
      margin-top: 3rem;

      .item {
        width: 18.81rem;
        height: 5.57rem;
        border-radius: .63rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2.65rem;
        background: #fff;
        color: #000;

        &:last-child {
          margin-right: 0;
        }

        &.left {
          background: #FD00A9;
          color: #fff;
        }

        img {
          width: 2.16rem;
          margin-right: .86rem;

        }

        .title1 {
          font-size: .97rem;
        }

        .title2 {
          font-size: 1.3rem;
        }

      }
    }

  }


}


.views-2 {
  color: #fff;
  padding: 6rem 0;

  background-image: url('../assets/ido/views-2-back.png'); /* 设置背景图 */
  background-size: cover; /* 背景图铺满容器 */
  background-position: center 100%; /* 背景图居中显示 */
  background-repeat: no-repeat; /* 防止背景图重复 */
  text-align: center;

  .title {
    font-size: 1.98rem;
    margin-bottom: 3.5rem;

  }

  .time-views {
    margin-bottom: 5rem;

    .item {
      width: 10rem;
      color: #F137BE;
      font-size: 4.75rem;

      .num-view {
        min-height: 12rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        font-family: Fontquan, serif;
        font-weight: bold;
        border-radius: .54rem;
        border: 0.11rem solid #F772D1;
      }

      .time-title {
        font-size: 1.58rem;
        color: rgba(255, 255, 255, 0.3);
        margin-top: 1rem;

      }


    }

    .mao-hao {
      margin: 0 1.95rem;
      font-size: 4.75rem;
      transform: translateY(-3rem);
    }

  }

  .msg {
    font-size:1.3rem;
    width: 59rem;
    max-width: 80%;
    line-height: 1.9rem;
    color: rgba(255,255,255,.6);
    margin-bottom: 3rem;
  }

  .x-views{
    font-size: 1.62rem;
    img{
      width: 3.35rem;
      margin-right: 1.3rem;

    }
  }

}


@media screen and (max-width: 768px) {
  .views-1{
    .right-back-img {
      width: 39rem;
      transform: translateX(30%);

    }
  }




}



</style>
