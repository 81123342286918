<template>
  <div class="html-bottom-views flex align-center">
    <div class="def-max-width flex flex-direction align-center">
      <div class="body-views flex justify-between">
        <div class="left-logo-views flex flex-direction align-center">
          <img class="logo" src="../assets/home/bottom-logo.png" alt=""/>
          <span>Web3.0 Video Dating Platform</span>
        </div>
        <div class="zong-views flex justify-between">
          <div class="item flex flex-direction">
            <span class="title">LINKS</span>
            <span class="txt">WHITEPAPER</span>
            <span class="txt">IDO</span>
          </div>
          <div class="item flex flex-direction">
            <span class="title">LEGAL</span>
            <span class="txt">TERES & CONDITIONS</span>
            <span class="txt">PRIVACY POLICY</span>
          </div>
        </div>
        <div class="email-views flex flex-direction align-center">
          <span class="title">Stay up to date on all things AngelBlock</span>
          <div class="input-views flex flex-direction align-stretch">
            <div class="input-view flex">
              <el-input class="input" v-model="email" placeholder="Enter your email" />
              <div class="button flex justify-center align-center">
                Subscribe
              </div>
            </div>
            <span class="msg">No spam, ever. Unsubscribe anytime.</span>
          </div>
        </div>
      </div>
      <div class="out-line-view">
        Copyright © 2023 HANI PARK
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomView",
  props:{
    showMs:{default:false,type:[String,Boolean]}
  },
  data() {
    return {
      email:""
    }
  }
}
</script>
<style lang="scss" scoped>
.html-bottom-views {
  //border-top: 1px solid #3a3a3a;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 60%;                  /* 上边框的长度（相对于容器宽度） */
    height: 1px;                 /* 上边框的高度 */
    background: linear-gradient(to right, transparent, #666, transparent);
    transform: translateX(-50%);
  }

  color: #fff;
  width: 100%;
  min-height: 22rem;
  padding: 2.8rem 0.5rem;
  .body-views{
    align-self: stretch;
    height: 100%;
  }
  .left-logo-views {
    font-size: .88rem;

    .logo {
      width: 11.46rem;
      margin-bottom: 1rem;
    }
  }


  .out-line-view{
    margin-top: 3rem;
    font-size: .86rem;

  }



  .zong-views{
    width: 28rem;
    max-width: 97%;
    .item{
      font-size: .86rem;line-height: 3;
      .title{
        font-size: 1.08rem;
        font-weight: bold;
      }

      .txt{
        cursor: pointer;

      }
    }

  }


  .email-views{
    .title{
      font-size: 1.08rem;
      margin-bottom: 1.5rem;
      font-weight: bold;
    }

    .input-views{

      .input-view{
        border: .11rem solid #5E5E5E;
        border-radius: .54rem;
        padding: .6rem;
      }

      .input{
        flex: 1 0 50%;
        margin-right: .5rem;

        ::v-deep .el-input__wrapper{
          background: transparent;
          box-shadow: none;
        }

      }
      .button{
        width: 7.08rem;
        background: #EEEAF8;
        border-radius: .54rem;
        color: #000000;
      }

      .msg{
        margin: 1rem .5rem;
        font-size: .86rem;
        color: #c2c2c2;
      }
    }


  }

}



@media screen and (max-width: 768px) {
  .html-bottom-views {
    .body-views{
      flex-direction: column;
    }

    .left-logo-views {
      font-size: 2.6rem;

      .logo {
        width: 17rem;
      }
    }

    .out-line-view{
      font-size: 1.7rem;

    }

    .zong-views{
      align-self: center;
      width: 95%;
      margin: 5rem 0;
      .item{
        font-size: 2.6rem;
        .title{
          font-size: 3rem;
        }
      }

    }

    .email-views{
      .title{
        font-size: 3rem;
      }

      .input-views{
        align-self: stretch;
        .input-view{
          padding: 1.4rem;
          margin-top: 2rem;

        }

        .input{

        }
        .button{
          width: 18rem;
          border-radius: 1rem;
          font-size: 2.8rem;
          padding: 2rem 0;
        }

        .msg{
          margin: 2rem 1rem;
          font-size: 2.3rem;
        }
      }


    }

  }

}


</style>
