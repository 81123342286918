import {createMemoryHistory, createRouter, createWebHashHistory, createWebHistory} from 'vue-router'


import Home from "@/home/home.vue";
import Ido from "@/home/Ido.vue";


const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {path: '/', component: Home},
        {path: '/ido', component: Ido},
    ],
})

export default router;

