import store from '../store/index.js';

const baseUrl = '';

const http = 'http://' + baseUrl + '/api';
const wsUrl = 'ws://' + baseUrl + '/ws';



const imgHttp = '';
const backImgHttp = '';
const https = {


	"null": "null"
}

const GlobalConstantsString = {


	"null": "null"
}

/**
 * 所有号段
 * 中国电信号段
 133、149、153、173、177、180、181、189、199、191
 中国联通号段
 130、131、132、145、155、156、166、171、175、176、185、186、166
 中国移动号段
 134(0-8)、135、136、137、138、139、147、150、151、152、157、158、159、172、178、182、183、184、187、188、198
 其他号段
 14号段以前为上网卡专属号段，如中国联通的是145，中国移动的是147等等。
 虚拟运营商
 电信：1700、1701、1702
 移动：1703、1705、1706
 联通：1704、1707、1708、1709、171
 卫星通信：1349
 * @type {RegExp}
 */
var PHONE_REG = /^((13[0-9])|(14[5,7,9])|(15[0-3,5-9])|(17[0-3,5-8])|(18[0-9])|166|(19[0,1,2,3,5,6,7,8,9]))[0-9]{8}$/;

const now = Date.now || function() {
	return new Date().getTime();
};
const isArray = Array.isArray || function(obj) {
	return obj instanceof Array;
};

/**
 * 判断对象是否为null 或者 undefined  或者 ""  或者 "null"
 * @param {Object} obj
 */
const isBlank = function(obj) {
	return (obj == null || obj == undefined || obj === "" || obj == "null" || obj == "undefined" || (typeof obj ==
		'object' && Object.keys(obj).length == 0 && !obj.name) || (typeof obj == 'object' && obj.length &&
		obj.length == 0) || (typeof obj == 'function' && 1 != 1))
}

const extend = function deepMerge(obj1, obj2) {
	let key;
	for (key in obj2) {
		// 如果target(也就是obj1[key])存在，且是对象的话再去调用deepMerge，否则就是obj1[key]里面没这个对象，需要与obj2[key]合并
		// 如果obj2[key]没有值或者值不是对象，此时直接替换obj1[key]
		obj1[key] =
			obj1[key] &&
			obj1[key].toString() === "[object Object]" &&
			(obj2[key] && obj2[key].toString() === "[object Object]") ?
			deepMerge(obj1[key], obj2[key]) :
			(obj1[key] = obj2[key]);
	}
	return obj1;
}


var lastToastTime = 0; //最后一次Toast时间
/**
 * 网络请求
 * @param {Object} obj
 */
const request = function(obj) {
	// if (!store.state.hasLogin) {
	// 	requestOrdinary(obj);
	// 	return;
	// }
	var timestamp = (new Date()).getTime();
	var nonce = utilsMethods.randomString(32);
	let token = isBlank(store.state.loginData) ? null : store.state.loginData.token.token_session

	var useComplete = obj.complete;
	delete obj.complete;
	let defObj = {
		method: "post",
		timeout: 12000,
		//sslVerify:false,
		header: {
			// "content-type": "application/x-www-form-urlencoded",
			// nonce: nonce
		},
		data: {
			token: token,
			local: uni.getLocale()
		},
		complete: (XMLHttpRequest, status) => {
			console.log(XMLHttpRequest);
			if (XMLHttpRequest.data && XMLHttpRequest.data.code == 500) { //说明需要登录
				store.commit("setLoginData", null);

				uni.showToast({
					title: "Login expired"
				})

				uni.reLaunch({
					url: "/pages/login/login"
				})
			}
			if (!isBlank(useComplete) && useComplete(XMLHttpRequest,
					status)) { //return true 就是说自行写的complete已经处理了complete逻辑
				return;
			}
			if (status === 'timeout') { //超时,status还有success,error等值的情况
				if ((new Date().getTime() - lastToastTime) > 2000) {
					lastToastTime = new Date().getTime();
					// uni.showToast({
					// 	title: '网络异常,请检查网络',
					// 	icon: 'none'
					// });
				}
				//console.log("网络异常:"+JSON.stringify(defaultParams));
			} else if (status === "error") {
				if ((new Date().getTime() - lastToastTime) > 2000) {
					lastToastTime = new Date().getTime();
					// uni.showToast({
					// 	title: '服务器开小差了,请稍后重试',
					// 	icon: 'none'
					// });
				}
			}

		}
	}

	defObj = extend(defObj, obj);

	//对null的数据做处理, 因为 如果mui把null转成了"null"
	for (var a in defObj.data) {
		if (defObj.data[a] == null || defObj.data[a] == undefined) {
			delete defObj.data[a];
		}
	}


	// console.log(JSON.stringify(defObj));
	return uni.request(defObj)
}
/**
 * 网络请求  不需要登录的 就是普通请求
 * @param {Object} obj
 */
const requestOrdinary = function(obj) {
	var timestamp = (new Date()).getTime();
	let defObj = {
		method: "post",
		timeout: 6000,
		//sslVerify:false,
		header: {
			"content-type": "application/x-www-form-urlencoded"
		},
		data: {}
	}

	defObj = extend(defObj, obj);
	//对null的数据做处理, 因为 如果mui把null转成了"null"
	for (var a in defObj.data) {
		if (isBlank(defObj.data[a])) {
			delete defObj.data[a];
		}
	}
	// console.log(JSON.stringify(defObj));
	return uni.request(defObj)
}

const commonMethods = {
	login(data) {
		getApp().globalData.token = data.data.token;
		getApp().globalData.user = data.data.user;
		uni.setStorageSync('token', getApp().globalData.token);
		uni.setStorageSync('user', getApp().globalData.user);
	},
	loginOut() {
		getApp().globalData.token = null;
		getApp().globalData.user = null;
		uni.removeStorageSync('token');
		uni.removeStorageSync('user');
	}
}

const utilsMethods = {
	openNewTab(url,target = '_blank') {
		window.open(url, target);
	},
	/**
	 * 生成指定长度的随机字符串
	 * @param {Object} len
	 */
	randomString(len) {
		len = len || 32;
		var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
		var maxPos = $chars.length;
		var pwd = '';
		for (var i = 0; i < len; i++) {
			pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
		}
		return pwd;
	},
	/**
	 * 生成uuid
	 * @returns {Uint8Array|number|number[]|*|string}
	 */
	generateUUID() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			const r = (crypto.getRandomValues(new Uint8Array(1))[0] % 16) | 0;
			const v = c === 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	},
	// 价格，小数点后几位，替换$，千分位显示什么符号默认'，'  ，  小数点'.'
	formatMoney(number, places, symbol, thousand, decimal) {
		number = number || 0;
		places = !isNaN(places = Math.abs(places)) ? places : 2;
		symbol = symbol !== undefined ? symbol : "￥";
		thousand = thousand || ",";
		decimal = decimal || ".";
		var negative = number < 0 ? "-" : "",
			a,
			i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
			j = (a = i.length) > 3 ? a % 3 : 0;
		return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g,
			"$1" +
			thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
		//再把'$12,345.67' 变成纯数字 12345.67
		//parseFloat('$12,345.67'.replace(/[^0-9-.]/g, ''))
	},
	/**************************************时间格式化处理************************************/
	dateFtt(fmt, date) { //author: meizz
		var o = {
			"M+": date.getMonth() + 1, //月份
			"d+": date.getDate(), //日
			"h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, //小时
			"H+": date.getHours(), //小时
			"m+": date.getMinutes(), //分
			"s+": date.getSeconds(), //秒
			"q+": Math.floor((date.getMonth() + 3) / 3), //季度
			"S": date.getMilliseconds() //毫秒
		};
		var week = {
			"0": "/u65e5",
			"1": "/u4e00",
			"2": "/u4e8c",
			"3": "/u4e09",
			"4": "/u56db",
			"5": "/u4e94",
			"6": "/u516d"
		};
		if (/(y+)/.test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
		}
		if (/(E+)/.test(fmt)) {
			fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "/u661f/u671f" :
				"/u5468") : "") + week[date.getDay() + ""]);
		}
		for (var k in o) {
			if (new RegExp("(" + k + ")").test(fmt)) {
				fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k])
					.length)));
			}
		}
		return fmt;
	},
	/**
	 * 返回两个时间的时间间隔
	 * @param {Object} oldTime
	 * @param {Object} newTime
	 */
	timeInterval(oldTime, newTime) { //di作为一个变量传进来
		//如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
		var dateBegin = oldTime; //将-转化为/，使用new Date
		var dateEnd = newTime; //获取当前时间
		var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
		var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
		var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
		var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
		//计算相差分钟数
		var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
		var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
		//计算相差秒数
		var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
		var seconds = Math.round(leave3 / 1000)
		//console.log(" 相差 "+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒")
		//console.log(dateDiff+"时间差的毫秒数",dayDiff+"计算出相差天数",leave1+"计算天数后剩余的毫秒数"
		//,hours+"计算出小时数",minutes+"计算相差分钟数",seconds+"计算相差秒数");
		return [dayDiff, hours, minutes, seconds];
	},
	/**
	 * 前置补0 大过最大位数会舍去
	 * @param val  需要补的
	 * @param length 总长度
	 */
	completionNumber(val, length) {
		if (isBlank(length)) { //兼容es5
			length = 2;
		}
		return (Array(length).join(0) + val).slice(-length);
	},
	/**
	 * 加法运算，避免数据相加小数点后产生多位数和计算精度损失。
	 *
	 * @param num1加数1 | num2加数2
	 */
	numAdd(num1, num2) {
		var baseNum, baseNum1, baseNum2;
		try {
			baseNum1 = num1.toString().split(".")[1].length;
		} catch (e) {
			baseNum1 = 0;
		}
		try {
			baseNum2 = num2.toString().split(".")[1].length;
		} catch (e) {
			baseNum2 = 0;
		}
		baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
		return (num1 * baseNum + num2 * baseNum) / baseNum;
	},
	/**
	 * 加法运算，避免数据相减小数点后产生多位数和计算精度损失。
	 *
	 * @param num1被减数  |  num2减数
	 */
	numSub(num1, num2) {
		var baseNum, baseNum1, baseNum2;
		var precision; // 精度
		try {
			baseNum1 = num1.toString().split(".")[1].length;
		} catch (e) {
			baseNum1 = 0;
		}
		try {
			baseNum2 = num2.toString().split(".")[1].length;
		} catch (e) {
			baseNum2 = 0;
		}
		baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
		precision = (baseNum1 >= baseNum2) ? baseNum1 : baseNum2;
		return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision);
	},
	/**
	 * 乘法运算，避免数据相乘小数点后产生多位数和计算精度损失。
	 *
	 * @param num1被乘数 | num2乘数
	 */
	numMulti(num1, num2) {
		var baseNum = 0;
		try {
			baseNum += num1.toString().split(".")[1].length;
		} catch (e) {}
		try {
			baseNum += num2.toString().split(".")[1].length;
		} catch (e) {}
		return Number(num1.toString().replace(".", "")) * Number(num2.toString().replace(".", "")) / Math.pow(10,
			baseNum);
	},
	/**
	 * 除法运算，避免数据相除小数点后产生多位数和计算精度损失。
	 *
	 * @param num1被除数 | num2除数
	 */
	numDiv(num1, num2) {
		var baseNum1 = 0,
			baseNum2 = 0;
		var baseNum3, baseNum4;
		try {
			baseNum1 = num1.toString().split(".")[1].length;
		} catch (e) {
			baseNum1 = 0;
		}
		try {
			baseNum2 = num2.toString().split(".")[1].length;
		} catch (e) {
			baseNum2 = 0;
		}
		baseNum3 = Number(num1.toString().replace(".", ""));
		baseNum4 = Number(num2.toString().replace(".", ""));
		return (baseNum3 / baseNum4) * pow(10, baseNum2 - baseNum1);
	},
	/**
	 * url追加参数
	 * @param uri
	 * @param key
	 * @param value
	 * @returns {string|*}
	 */
	updateQueryStringParameter(uri, key, value) {
		if (!value) {
			return uri;
		}
		var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
		var separator = uri.indexOf('?') !== -1 ? "&" : "?";
		if (uri.match(re)) {
			return uri.replace(re, '$1' + key + "=" + value + '$2');
		} else {
			return uri + separator + key + "=" + value;
		}
	},
	//字符串转byte[]
	stringToByteArray(str) {
		console.log(str)
		return new Uint8Array(str.split('').map(char => char.charCodeAt(0)));
	},
	/**
	 * 倒计时把秒数转成 00:32这种格式
	 * @param time 倒计时秒数
	 * @returns {string}
	 */
	formatMSTime(time) {
		const secondsInMinute = 60;
		const secondsInHour = 24;
		let t = time;
		let ss = t % secondsInMinute;
		t = (t - ss) / secondsInMinute;
		const mm = t % secondsInMinute;
		t = (t - mm) / secondsInMinute;
		const hh = t % secondsInHour;
		t = (t - hh) / secondsInHour;
		const dd = t;
		return `${mm >= 10 ? mm : "0" + mm}:${ss >= 10 ? ss : "0" + ss}`;
	},
	getLocalTime(time, num) {
		time = parseFloat("" + time);
		let currentData = new Date();
		let date = new Date(time);
		let y = date.getFullYear();
		let month =
			date.getMonth() + 1 < 10 ?
			"0" + (date.getMonth() + 1) :
			date.getMonth() + 1;
		let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
		let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
		let m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
		let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
		if (num == 1) {
			return y + "-" + month + "-" + d + " " + h + ":" + m + ":" + s;
		} else if (num == 2) {
			return y + "-" + month + "-" + d;
		} else if (num == 3) {
			return y + "." + month + "." + d;
		} else if (num == 4) {
			return y + "." + month + "." + d + " " + h + ":" + m + ":" + s;
		} else if (num == 5) {
			return month + "-" + d + " " + h + ":" + m;
		} else if (num == 6) {
			return y == currentData.getFullYear() ?
				month + "-" + d + " " + h + ":" + m :
				y + "-" + month + "-" + d + " " + h + ":" + m;
		} else if (num == 7) {
			return h + ":" + m + ":" + s;
		} else if (num == 8) {
			return y + "-" + month + "-" + d + " " + h + ":" + m;
		} else if (num == 9) {
			return y + "-" + month;
		} else if (num == 10) {
			return y + "-" + month + "-" + d + " " + h + ":" + m;
		} else if (num == 11) {
			return month + "-" + d;
		} else if (num == 12) {
			return y + "-" + month + "-" + d + " " + h + ":" + m;
		} else if (num == 13) {
			return month + "/" + d + " " + h + ":" + m;
		} else if (num == 14) {
			return y + "年" + month + "月" + d + "日" + "下午 12:00 - 17:00";
		} else if (num == 15) {
			return `${y}${month}${d}`;
		} else if (num == 16) {
			return y + "/" + month;
		} else if (num == 17) {
			return y + "/" + month + "/" + d;
		} else if (num == 18) {
			return y + "年" + month + "月";
		} else if (num == 19) {
			return y + "-" + month + "-" + d;
		} else if (num == 20) {
			return y + "年" + month + "月" + d + "日" + h + ":" + m;
		} else if (num == 21) {
			return y + "-" + month;
		} else if (num == 22) {
			return h + ":" + m;
		} else if (num == 23) {
			return y;
		} else if (num == 24) {
			return month;
		} else if (num == 25) {
			return y + "." + month + "." + d + " " + h + ":" + m;
		} else if (num == 88) {
			return Number(h) * 60 * 60 * 1000 + Number(m) * 60 * 1000;
		} else if (num == 99) {
			return y + "/" + month + "/" + d + " " + h + ":" + m + ":" + s;
		} else if (num == 66) {
			return y + "/" + month + "/" + d + " " + h + ":" + m;
		} else if (num == 10086) {
			return `${h}${m}` == "0000";
		}
	}
}



export default {
	http,
	wsUrl,
	https,
	PHONE_REG,
	imgHttp,
	isBlank,
	now,
	// md5,
	request,
	requestOrdinary,
	GlobalConstantsString,
	extend,
	commonMethods,
	utilsMethods,
	isArray
}
