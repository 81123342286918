<template>
  <div class="header-views flex align-center">
    <div class="header-body def-max-width flex justify-between">
      <div class="left-views">
        <img class="logo-view" src="../assets/header/logo.png" alt=""/>
      </div>
      <div class="right-views flex justify-between align-center">
        <div @click="onClickTo('/')" class="item" :class="{active:selectFlag == 'home'}">HOME</div>
        <div @click="()=>{
          $common.utilsMethods.openNewTab('https://hanipark.gitbook.io/untitled/')
        }" class="item" :class="{active:selectFlag == 'whitepaper'}">WHITEPAPER</div>
        <div @click="onClickTo('/ido')"  class="item" :class="{active:selectFlag == 'ido'}">IDO</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    selectFlag:{
      type: String,       // 期待的类型
      required: true,     // 是否是必需的
      default: 'home', // 默认值
    }
  },
  name: "header",
  data() {
    return {}
  },
  methods:{
    onClickTo(path){
      if(path == 'pdf'){

      }else{
        this.$router.push({path:path})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header-body{
  padding: 0 2rem;
}
.header-views {
  background: #000000;
  height: 8.7rem;
}

.left-views{
  .logo-view{
    width: 9.9rem;
    height: 3.4rem;
  }
}

.right-views{
  color: #fff;
  .item{
    cursor: pointer;
    font-size: 1.5rem;
    margin-right: 3.5rem;

    &:last-child{
      margin-right: 0;
    }
  }
  .active{
    color: #FB26B6;
  }
}

</style>
